





































































































































































































































































































































































































































































































































































































































































































































































































import { defineComponent } from '@vue/composition-api'
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import cancelSaveDialog from '@/mixins/cancelSaveDialog.js'
import { mapFields } from 'vuex-map-fields'
import { RawLocation } from 'vue-router'
import { NeedTraining } from '@/api/interfaces/needtraining'
import { NeedTrainingStatus } from '@/api/interfaces/needtrainingstatus'
import { Reminder } from '@/api/interfaces/reminder'
import { TrainingType } from '@/api/interfaces/trainingtype'
import { Booking } from '@/api/interfaces/booking'
import { driverProgram } from '@/config-constantes'
import SelectSearchTrainingType from '@/components/molecules/select-search/trainingType.vue'
import { useDate } from '@/composables/date'

export default defineComponent({
  name: 'NeedTrainingEdit',
  components: {
    SelectSearchTrainingType,
    navFormation: () => import('@/components/molecules/m-nav-formation.vue'),
    SelectCompanies: () =>
    import('@/components/molecules/select-search/company.vue'),
    SelectManagers: () =>
    import('@/components/molecules/select-search/managers.vue'),
    SelectCarDealerSeller: () =>
    import('@/components/molecules/select-search/carDealerSeller.vue'),
    SelectCarDealer: () =>
    import('@/components/molecules/select-search/carDealer.vue'),
    SelectCarBrand: () =>
    import('@/components/molecules/select-search/carBrand.vue'),
    SelectCarType: () =>
    import('@/components/molecules/select-search/carType.vue'),
    SelectCarModel: () =>
    import('@/components/molecules/select-search/carModel.vue'),
    SelectCarEngine: () =>
    import('@/components/molecules/select-search/carEngine.vue'),
    SelectCarFinish: () =>
    import('@/components/molecules/select-search/carFinish.vue'),
    SelectCarColor: () =>
    import('@/components/molecules/select-search/carColor.vue'),
    SelectRenter: () =>
    import('@/components/molecules/select-search/renter.vue'),
    CancelUpdateDialog: () => import('@/components/cancel-update-dialog.vue'),
    DateField: () => import('@/components/base/DateField.vue'),
    ReminderForm: () => import('./components/ReminderForm.vue'),
    ScrollTop: () => import('@/components/base/ScrollTop.vue'),
  },
  mixins: [cancelSaveDialog],

  data () {
    return {
      fab: false,
      selecteditem: null,
      reminderToEdit: null,
      dialogReminderEdit: false,
      navigation: [
        {
          link: '#formations',
          label: this.$t('trainee.form.formation.formation'),
        },
        {
          link: '#entreprises',
          label: this.$t('trainee.form.formation.companies'),
        },
        {
          link: '#gestionnaires',
          label: this.$t('trainee.form.formation.managers'),
        },
        { link: '#commande', label: this.$t('trainee.form.formation.order') },
        { link: '#loueur', label: this.$t('trainee.form.formation.renter') },
        {
          link: '#concession',
          label: this.$t('trainee.form.formation.concession'),
        },
        {
          link: '#commercial',
          label: this.$t('trainee.form.formation.commercial'),
        },
        { link: '#vehicule', label: this.$t('trainee.form.formation.car') },
        {
          link: '#reminder',
          label: this.$t('trainee.form.formation.reminder'),
        },
        {
          link: '#training_session',
          label: this.$t('trainee.form.formation.training_session'),
        },
        {
          link: '#commentaire',
          label: this.$t('trainee.form.formation.comment'),
        },
      ],
      showCreateEditReminderForm: false,
      headersTrainingSession: [
        {
          align: 'center',
          text: this.$t('training_session.list.headers.trainingType'),
          value: 'trainingType.name',
        },
        {
          align: 'center',
          text: this.$t('training_session.list.headers.trainingLocation'),
          value: 'trainingLocation.name',
        },
        {
          align: 'center',
          text: this.$t('training_session.list.headers.goal_number_trainees'),
          value: 'goalNumberTrainees',
        },
        {
          align: 'center',
          text: this.$t('training_session.list.headers.status'),
          value: 'status',
        },
        {
          align: 'center',
          text: this.$t('training_session.list.headers.at'),
          value: 'slotTime',
        },
        {
          align: 'center',
          text: this.$t('training_session.list.headers.link'),
          value: 'link',
        },
      ],
    }
  },
  computed: {
    ...mapState('needTrainingForm', {
      needTraining: 'needTraining',
    }),
    ...mapState('booking', {
      booking: 'booking',
      fromBooking: 'fromBooking',
    }),
    ...mapFields('needTrainingForm', [
      'needTraining.@id',
      'needTraining.id',
      'needTraining.name',
      'needTraining.trainingType',
      'needTraining.status',
      'needTraining.company',
      'needTraining.branch',
      'needTraining.manager',
      'needTraining.carDealer',
      'needTraining.carDealerSeller',
      'needTraining.businessIntroducer',
      'needTraining.commandNoOne',
      'needTraining.commandNoTwo',
      'needTraining.commandDate',
      'needTraining.renter',
      'needTraining.carBrand',
      'needTraining.carModel',
      'needTraining.carType',
      'needTraining.carEngine',
      'needTraining.carFinish',
      'needTraining.carColor',
      'needTraining.carNumberPlate',
      'needTraining.vehicleDelivered',
      'needTraining.commentary',
      'needTraining.reminders',
      'needTraining.trainingSession',
      'needTraining.zipCode',
      'needTraining.city',
      'needTraining.estimatedDeliveryDate',
      'needTraining.deliveryDate',
      'needTraining.originDriverProgram',
      'needTraining.sendToCallCenterAt',
    ]),
    ...mapState('status', {
      statusesList: 'listWorkflow',
    }),
    ...mapState('needTrainingForm', {
      isNeedTrainingSubmited: 'isSubmitted',
      isFormChanged: 'isChanged',
    }),
    ...mapState('company', {
      businessIntroducerToyota: 'businessIntroducerToyota',
    }),
    ...mapGetters('navigation', {
      returnPreviousRoute: 'getReturnPreviousRoute',
    }),
    ...mapGetters('logisticSessionForm', {
      getCurrentAssociationWithIdTrainingSession: 'getCurrentAssociationWithIdTrainingSession',
    }),
    getNeedTrainingId () {
      return this['@id']
    },
    showTrainingSession () {
      return this.getNeedTrainingId && (this.statusesList as NeedTrainingStatus[]).find((s: NeedTrainingStatus) => { return s.code === 'planifie' || s.code === 'forme' })
    },
    showBookingAssociation () {
      return this.fromBooking && !(this.booking as Booking).needTraining &&
        !(this.needTraining as NeedTraining).trainingSession
    },
  },
  created () {
    this.loadWorkflow(this.id || '')
  },
  watch: {
    id () {
      this.loadWorkflow(this.id || '')
    },
    trainingType (newValue: TrainingType) {
      if (newValue.specificToyota) {
        this.businessIntroducer = this.businessIntroducerToyota
      }

      if (newValue.code !== driverProgram) {
        this.originDriverProgram = null
      }
    },
  },
  methods: {
    useDate,
    ...mapActions('status', {
      loadWorkflow: 'loadWorkflow',
    }),
    ...mapActions('needTrainingForm', {
      saveNeedTraining: 'save',
    }),
    ...mapMutations('needTrainingForm', {
      addReminder: 'addReminder',
      resetTrainingSession: 'resetTrainingSession',
      associateTrainingSessionToNeedTraining: 'associateTrainingSession',
    }),
    ...mapActions('user', {
      sendSuccessMessage: 'sendSuccessMessage',
    }),
    ...mapMutations('logisticSessionForm', {
      addNeedTrainingToTrainingSession: 'addNeedTrainingToTrainingSession',
    }),
    ...mapMutations('navigation', {
      forceCancelSaveNextRoute: 'forceCancelSaveNextRoute',
    }),
    ...mapActions('booking', {
      associateNeedTrainingToBooking: 'associateNeedTrainingToBooking',
    }),
    replaceReminder (reminder: Reminder, reminderToReplace: Reminder) {
      if ((this.needTraining as NeedTraining)?.reminders && reminder) {
        ((this.needTraining as NeedTraining).reminders as Array<Reminder>).find((x: Reminder, key: number) => {
          if (JSON.stringify(x) === JSON.stringify(reminderToReplace)) {
            this.$set((this.needTraining as NeedTraining)?.reminders as Array<Reminder>, key, reminder)
            return x
          }
        })
      }
    },
    dissociate () {
      this.resetTrainingSession()
    },
    associateToANewTrainingSession () {
      const route: RawLocation = {
      name: 'TrainingSession List Associate NeedTraining',
      params: { idNeedTraining: this.id },
    } as RawLocation
      this.forceCancelSaveNextRoute(route)
      this.$router.push(route)
    },
    associateToAnotherTrainingSession () {
      this.dissociate()
      this.associateToANewTrainingSession()
    },
    returnPrevious () {
      let route: RawLocation = { name: 'Trainee List' }
      if (this.returnPreviousRoute) {
        route = this.returnPreviousRoute as RawLocation
      }
      this.forceCancelSaveNextRoute(route)
      this.$router.push(route)
    },
    async onSubmit () {
      const isValidatedForm = await (this as any).isValidatedForm(this.$refs.validationObserver)
      if (isValidatedForm) {
        const needTraining: NeedTraining = await this.save()
        this.sendSuccessMessage('trainee.form.formation.saved')
        this.loadWorkflow(needTraining.id)
        if (this.returnPreviousRoute) {
          this.addNeedTrainingToTrainingSession({
            idTrainingSession: this.getCurrentAssociationWithIdTrainingSession,
            needTraining: needTraining,
          })
        }
        this.returnPrevious()
      }
    },
    async save () {
      const needTraining: NeedTraining = await this.saveNeedTraining()
      if (!(this.booking as Booking).needTraining && needTraining.trainingSession &&
        (this.booking as Booking).trainingSession.id === needTraining.trainingSession.id) {
        await this.associateNeedTrainingToBooking(needTraining)
      }
      return needTraining
    },
    async associateToBookingTrainingSession () {
      this.associateTrainingSessionToNeedTraining((this.booking as Booking).trainingSession)
    },
  },
})
